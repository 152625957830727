// Styles for AwardsRecognition go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { CARD_OVERVIEW_CONSTANTS as constants } from '../../../modules/constants/constants';

export const StyledStickyCardSection = styled.div`
  height: 100%;
  .card-art-badge-top {
    margin-top: -65%;
    @media ${baseVariables.mediaQuery.md} {
      margin-top: 0;
    }
  }
  .img-size {
    width: ${toRem(constants.stickyCardImageVertical.mobileWidth)};
    @media ${baseVariables.mediaQuery.md} {
      width: ${toRem(constants.stickyCardImageVertical.tabletWidth)};
    }
    @media ${baseVariables.mediaQuery.lg} {
      width: ${toRem(constants.stickyCardImageVertical.desktopWidth)};
    }
  }
  .badge-width {
    max-width: ${toRem(constants.stickyCardImageVertical.mobileWidth)};
    @media ${baseVariables.mediaQuery.md} {
      max-width: ${toRem(constants.stickyCardImageVertical.tabletWidth)};
    }
    @media ${baseVariables.mediaQuery.lg} {
      max-width: ${toRem(constants.stickyCardImageVertical.desktopWidth)};
    }
  }

  .sticky-card {
    &-image {
      width: ${toRem(constants.stickyCardImageDimension.mobileWidth)};
      @media ${baseVariables.mediaQuery.sm} {
        width: ${toRem(constants.stickyCardImageDimension.tabletWidth)};
      }
      @media ${baseVariables.mediaQuery.lg} {
        width: ${toRem(constants.stickyCardImageDimension.desktopWidth)};
      }

      &--large {
        width: ${toRem(constants.stickyCardImageVertical.mobileWidth)};
        margin-top: -48%;
        @media ${baseVariables.mediaQuery.md} {
          width: ${toRem(constants.stickyCardImageVertical.tabletWidth)};
          margin-top: 0;
        }
        @media ${baseVariables.mediaQuery.lg} {
          width: ${toRem(constants.stickyCardImageVertical.desktopWidth)};
        }
      }
    }
    /*Below Styles are for RTE section*/
    &-heading p {
      margin-bottom: 0;
    }
    &-links a {
      text-decoration: underline;
      font-size: ${toRem(14)};
      font-weight: 500;
      text-underline-offset: ${toRem(2)};
    }
    &-heading .displayOnDesktop {
      display: none;
      @media ${baseVariables.mediaQuery.lg} {
        display: inline-block;
      }
    }
  }
  .badge-txt-align {
    height: ${toRem(8)};
  }
  .no-border-top-bottom {
    hr {
      display: none;
    }
  }
  .points-underlined {
    font-family: ${baseVariables.font.fontSwissCondensed};
    border-bottom: ${toRem(5)} ${baseVariables.border.borderStyleDefault} ${baseVariables.color.accent10};
  }
`;
